import React from 'react'
import {
  Grid,
  Box,
} from 'theme-ui';
import ReactPlayer from 'react-player/wistia';

import frameBorder from '../images/frame.jpg';


function MainContent(props) {
  return (
    <Box>
      <Grid
        sx={{
          background: `url(${frameBorder})`,
          alignItems: 'center',
          backgroundSize: 'cover',
          height: 'calc(46.5vw)',
          maxHeight: 517,
          maxWidth: 900,
          borderRadius: 10,
          width: '80%',
          margin: 'auto',
          px: 3
        }}>
        <Box
          sx={{
            padding: '56.25% 0 0 0',
            position: 'relative',
          }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0
            }}>
            <ReactPlayer
              url='https://vizio.wistia.com/medias/oi2bjmuhau'
              height='100%'
              width='100%'
              frameBorder='0'
              playing={true}
              controls={true}
              onEnded={() => {
                window.location.href = '/survey';
              }}
              style={{
                position: 'relative',
              }}
              config={{
                wistia: {
                  options: {
                    playbar: false,
                    smallPlayButton: false
                  }
                }
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}

export default MainContent;
