import React from 'react';
import HubspotForm from 'react-hubspot-form';
import {
  Box
} from 'theme-ui';
import theme from './theme';

import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

function ContactFormModal(props) {
  return (
    <Rodal
      visible={props.modalVisible}
      onClose={props.onClose}
      showCloseButton={true}
      height={600}
      closeOnEsc={true}
      customStyles={{
        background: theme.colors.background,
        maxHeight: '80%',
        maxWidth: '95%',
        borderRadius: 5,
        overflow: 'scroll'
      }}>
      <Box
        sx={{
          p: 3
        }}>
        <HubspotForm
          portalId='8555612'
          formId='f251b953-17cd-4df1-a6ed-db81282f7a16'
          submitButtonClass='hubspot-submit'
          inlineMessage="Thank you for submitting this form. We'll be in touch soon!"
          translations={{
            en: {
              submitText: 'Get in Touch'
            }
          }}
          cssClass='hubspot-form'
          loading={
            <Box
              sx={{
                color: 'white'
              }}>
              Connect with VIZIO
            </Box>
          }
        />
      </Box>
    </Rodal>
  )
}

export default ContactFormModal;
