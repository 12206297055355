import React, {
  useEffect,
  useRef
} from 'react';
import HubspotForm from 'react-hubspot-form';
import {
  Box,
} from 'theme-ui';
import theme from './theme';
import { gsap } from 'gsap';

import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

function EntranceFormModal(props) {
  const entranceModalRef = useRef(null);

  useEffect(() => {
    window.addEventListener('message', event => {
      if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        setTimeout(props.onClose, 2000);
      }
    });

    if (entranceModalRef.current) {
      gsap.from([entranceModalRef.current], {
        autoAlpha: 0,
        y: '-50px',
        duration: 0.5,
        ease: 'expo'
      });
    }
  })

  return (
    <Rodal
      visible={props.modalVisible}
      onClose={props.onClose}
      closeOnEsc={false}
      closeMaskOnClick={false}
      showCloseButton={false}
      customStyles={{
        background: theme.colors.background,
        height: 'auto',
        bottom: 'auto',
        top: '30%',
        maxWidth: '95%',
        borderRadius: 5,
      }}>
      <Box
        sx={{
          p: 3
        }}>
        <HubspotForm
          portalId='8555612'
          formId='6870df4b-444e-49a2-94f0-a31b292b9498'
          submitButtonClass='hubspot-submit'
          inlineMessage="Thanks! Enjoy this video from VIZIO."
          translations={{
            en: {
              submitText: 'View video'
            }
          }}
          cssClass='hubspot-form'
          loading={
            <Box
              ref={entranceModalRef}
              sx={{
                color: 'white'
              }}>
              Welcome to Beyond the Glass
            </Box>
          }
        />
      </Box>
    </Rodal>
  )
}

export default EntranceFormModal;
