import React from 'react';
import {
  ThemeProvider,
  Box
} from 'theme-ui';
import theme from '../components/theme';
import GradientBar from '../components/GradientBar';
import Header from '../components/Header';
import MainContent from '../components/MainContent';
import SEO from '../components/seo';
import EntranceFormModal from '../components/EntranceFormModal';
import ContactFormModal from '../components/ContactFormModal';

function Index() {
  const [contactModalIsOpen,setContactModalIsOpen] = React.useState(false);
  const [entranceFormModalIsOpen, setEntranceFormModalIsOpen] = React.useState(true);

  function openContactModal() {
    setContactModalIsOpen(true)
  }

  function closeContactModal() {
    setContactModalIsOpen(false)
  }

  function openEntranceFormModal() {
    setEntranceFormModalIsOpen(true)
  }

  function closeEntranceFormModal() {
    setEntranceFormModalIsOpen(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <SEO title="VIZIO's presentation at IAB NewFronts 2022" />
      <EntranceFormModal modalVisible={entranceFormModalIsOpen} onClose={closeEntranceFormModal} />
      <ContactFormModal modalVisible={contactModalIsOpen} onClose={closeContactModal} />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <GradientBar />
        <Header openContactModal={openContactModal} closeContactModal={closeContactModal} showContactCTA={true} />
        <Box
          sx={{
            margin: 'auto 0'
          }}>
          <MainContent modalVisible={entranceFormModalIsOpen} openModal={openEntranceFormModal} onClose={closeEntranceFormModal} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Index;
